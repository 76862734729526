<template>
  <div class="agent-card">
    <div class="agent-order">
      <div class="agent-order-hero">
        <h5>返現規則</h5>
        <template v-if="ruleLoaded">
          <p v-for="(item, index) in rules" :key="index">粉絲下單累計滿{{item.symbol}}{{item.money}}，可提現{{item.rate}}%</p>
        </template>
        <div class="text-center" v-else>
          <div class="loading-icon"></div>
        </div>
      </div>
      <div class="agent-order-plane">
        <fan-item :extra="true" v-for="(item, index) in orderList" :key="index" :item="item">
          <div>
            <router-link to="/agent/withdraw" v-if="!item.status">
              <button class="btn withdraw" v-if="item.money">
                返現
              </button>
            </router-link>
            <strong class="text-gray" v-else>{{item.status === 1 ? '返現中' : '已返現'}}</strong>
          </div>
        </fan-item>
        <div class="text-center mt-1">
          <span class="text-small" v-if="page > total && orderList.length">沒有更多了</span>
          <div v-else>
            <p class="text-small text-gray" v-if="fetching">
              加载中...
            </p>
            <button class="btn default btn-sm" @click="fetchOrderList" v-else-if="!fetching && orderList.length">加載更多</button>
            <template v-else>
              <span>還沒有粉絲下單呢</span>
              <p class="mt-1">
                <button class="btn primary" @click="FBInvite(user.uid)">邀請粉絲</button>
              </p>
            </template>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import AgentFansItem from '../../components/layout/AgentFansItem'
import api from 'src/api'
import { mapGetters } from 'vuex'
import FBShare from 'src/mixins/FBShare'
export default {
  name: '',
  mixins: [FBShare],
  components: {
    FanItem: AgentFansItem
  },
  data: () => ({
    ruleLoaded: false,
    dataLoaded: false,
    fetching: false,
    rules: [],
    orderList: [],
    page: 1,
    total: 1
  }),
  computed: {
    ...mapGetters(['user'])
  },
  created () {
    this.fetchWithdrawRules()
    this.fetchOrderList()
  },
  methods: {
    fetchWithdrawRules () {
      api.fetchAgentRules(this.user.countryCode).then(data => {
        data = data.map(item => {
          item.rate = Math.round(item.rate * 100)
          return item
        })
        this.rules = data
        this.ruleLoaded = true
      })
    },
    fetchOrderList () {
      if (this.fetching) return
      this.fetching = true
      if (this.page > this.total) return
      api.fetchAgentReferList(this.page).then(data => {
        const { pages } = data
        if (!this.dataLoaded) {
          this.dataLoaded = true
          this.total = pages
        }
        this.page++
        this.orderList = this.orderList.concat(data.data)
        this.fetching = false
      })
    }
  }
}
</script>

<style type="text/scss" lang="scss">
@import '../../styles/variables';
.agent {
  &-order {
    text-align: left;
    .btn.withdraw {
      border-color: transparent;
      background-color: #feecec;
      font-size: 14px;
      font-weight: 600;
      color: $el-color-primary;
    }
    &-hero {
      padding: 16px;
      font-size: 14px;
      min-height: 180px;
      background: {
        image: url(../../../static/images/placeholder/agent-rules.jpg);
        repeat: no-repeat;
        size: contain;
      };
    }
  }
}
</style>
